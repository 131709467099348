import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import actions from "../../../redux/actions/actions";
import { authUsers } from "../../../_mock/auth";
import { useNotify } from "../../../hooks/useNotify";
import { useAuth } from "../../../utils/AuthContext";
import appsApi, { signIn } from "../../../api/api";

export default function LoginForm() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const notfy = useNotify();
  const auth = useAuth();

  const signInMutate = useMutation({
    mutationFn: (data) => appsApi.postApi(signIn, data),
    mutationKey: signIn,
    onSuccess: (data) => {
      dispatch(actions.setAuthToken(data.token.access.token));
      dispatch(actions.setUserData(data.user));
    },
  });

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be valid")
      .required("Email is required"),
    password: Yup.string().required("Password is required").min(6).max(30),
  });

  const defaultValues = {
    username: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    await signInMutate.mutateAsync({
      email: data.email,
      password: data.password,
      userType: "super_admin",
    });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox name="remember" label="Remember me" />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={signInMutate.isLoading}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
