import {
  Box,
  ButtonBase,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { darken, styled } from "@mui/system";
import Iconify from "../../components/Iconify";
import { useCommon } from "../../utils/CommonContext";
import { LoadingButton } from "@mui/lab";

export const MainContainer = styled(({ children, ...props }) => (
  <Box {...props} px={[3, 3, 10, 10, 10]} mx="auto">
    {children}
  </Box>
))(({ theme }) => ({
  maxWidth: "1500px",
  width: "99vw",
}));

export const LinkText = styled(Typography)(({ theme }) => ({
  color: "white",
  padding: "10px 20px",
  cursor: "pointer",
  "&:hover": {
    color: darken("#FFFFFF", 0.3),
    textDecoration: "underline",
    textUnderlineOffset: "8px",
  },
  transition: theme.transitions.create(["color"], {
    duration: theme.transitions.duration.standard,
  }),
  fontSize: 14,
  userSelect: "none",
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    padding: "10px 20px",
  },
}));

export const SectionTitle = (props) => (
  <Typography
    component="span"
    color="web.white"
    fontWeight="semiBold"
    fontSize={[44, 44, 44, 65, 80]}
    {...props}
  >
    {props.children ? props.children : ""}
  </Typography>
);

export const linkHoverSx = {
  "&:hover": {
    "& .title": {
      borderBottom: "4px solid white",
    },
  },
  "& .title": {
    borderBottom: "4px solid transparent",
  },
};

export const NavbarWrapper = ({ children }) => (
  <Box mx={[0, 0, 0, 8, 0]}>
    <Stack
      direction="row"
      py={[1, 1, 2, 2, 3]}
      px={[2]}
      alignItems="center"
      justifyContent="space-between"
      maxWidth={["100%", "100%", "100%", "1280px", "1600px"]}
      mx="auto"
    >
      {children}
    </Stack>
  </Box>
);

export const ButtonCTA = styled(({ isFilled = false, ...rest }) => {
  const { inquiryForm } = useCommon();
  return (
    <ButtonBase onClick={inquiryForm.openInquiryForm} {...rest}>
      Let&apos;s Work Together&nbsp;&nbsp;&nbsp;
      <Iconify
        icon={
          isFilled
            ? "material-symbols:circle"
            : "material-symbols:circle-outline"
        }
      />
    </ButtonBase>
  );
})(({ theme, isFilled }) => ({
  userSelect: "none",
  cursor: "pointer",
  borderRadius: "30px",
  color: theme.palette.web.white,
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  ...(!isFilled && {
    border: "2px solid rgba(255, 255, 255, 0.15)",
    backgroundColor: "rgba(255, 255, 255, 0)",
  }),
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
  },
  fontSize: 16,
  padding: isFilled ? "16px 26px" : "14px 26px",
  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
    padding: isFilled ? "14px 22px" : "12px 22px",
  },
}));

export const WebButton = styled(({ isFilled = false, children, ...rest }) => (
  <ButtonBase {...rest}>
    {children}&nbsp;&nbsp;&nbsp;
    <Iconify
      icon={
        isFilled ? "material-symbols:circle" : "material-symbols:circle-outline"
      }
    />
  </ButtonBase>
))(({ theme, isFilled }) => ({
  userSelect: "none",
  cursor: "pointer",
  borderRadius: "30px",
  color: theme.palette.web.white,
  fontWeight: isFilled
    ? theme.typography.fontWeightSemiBold
    : theme.typography.regular,
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  ...(!isFilled && {
    border: "2px solid rgba(255, 255, 255, 0.15)",
    backgroundColor: "rgba(255, 255, 255, 0)",
  }),
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
  },
  fontSize: 16,
  padding: isFilled ? "16px 26px" : "14px 26px",
  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
    padding: isFilled ? "14px 22px" : "12px 22px",
  },
}));

export const LoadingWebButton = styled(
  ({ isFilled = false, children, isLoading = false, ...rest }) => (
    <LoadingButton {...rest} loading={isLoading}>
      {children}&nbsp;&nbsp;&nbsp;
      <Iconify
        icon={
          isFilled
            ? "material-symbols:circle"
            : "material-symbols:circle-outline"
        }
      />
    </LoadingButton>
  )
)(({ theme, isFilled }) => ({
  userSelect: "none",
  cursor: "pointer",
  borderRadius: "30px",
  color: theme.palette.web.white,
  fontWeight: isFilled
    ? theme.typography.fontWeightSemiBold
    : theme.typography.regular,
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  ...(!isFilled && {
    border: "2px solid rgba(255, 255, 255, 0.15)",
    backgroundColor: "rgba(255, 255, 255, 0)",
  }),
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.10)",
  },
  fontSize: 16,
  padding: isFilled ? "16px 26px" : "14px 26px",
  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
    padding: isFilled ? "14px 22px" : "12px 22px",
  },
}));

export const FormInput = styled("input")(({ theme, error }) => ({
  color: theme.palette.web.white,
  backgroundColor: "transparent",
  fontSize: "18px",
  border: "none",
  margin: "4px 16px",
  fontWeight: "bold",
  outline: "none",
  borderBottom: `1.6px solid ${
    error ? theme.palette.error.main : theme.palette.web.transparentWhite
  }`,
  "&:focus": {
    borderBottom: `1.6px solid ${theme.palette.web.white}`,
  },
  "&::placeholder": {
    fontWeight: 400,
    fontSize: "16px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "4px 0px",
  },
}));

export const FormInputArea = styled("textarea")(({ theme, error }) => ({
  color: theme.palette.web.white,
  backgroundColor: "transparent",
  fontSize: "18px",
  fontFamily: "'Inter', sans-serif",
  border: "none",
  outline: "none",
  maxHeight: 150,
  minHeight: "24px",
  minWidth: "100%",
  maxWidth: "100%",
  borderBottom: `1.6px solid ${
    error ? theme.palette.error.main : theme.palette.web.transparentWhite
  }`,
  "&:focus": {
    borderBottom: `1.6px solid ${theme.palette.web.white}`,
  },
  "&::placeholder": {
    fontWeight: 400,
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "4px 0px",
  },
}));
