import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FORM_OPTIONS } from "../constants";
import CustomDialog from "./CustomDialog";
import { useCommon } from "../../utils/CommonContext";
import { useFormField } from "../helpers/hooks";
import {
  FormInput,
  FormInputArea,
  LoadingWebButton,
} from "../style/styledComponents";
import { useMutation } from "react-query";
import appsApi, { enquiry } from "../../api/api";
import { useNotify } from "../../hooks/useNotify";

export default function InquiryForm(props) {
  const { inquiryForm } = useCommon();
  const theme = useTheme();
  const notify = useNotify();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const mutateEnquiry = useMutation({
    mutationFn: (data) => appsApi.postApi(enquiry, data),
    mutationKey: `${enquiry}Mutate`,
    onSuccess: (data) => {
      notify.toastSuccess("Enquiry Submitted Successfully");
      closeInquiryForm();
    },
  });

  const { isInquiryFormOpen, openInquiryForm, closeInquiryForm } = inquiryForm;
  const [selectedOptions, setSelectedOptions] = useState([
    FORM_OPTIONS.CONSULTATION,
  ]);

  const [nameField, nameProps] = useFormField("", {
    placeholder: "your full name",
  });

  const [designationField, designationProps] = useFormField("", {
    placeholder: "designation",
  });

  const [companyField, companyProps] = useFormField("", {
    placeholder: "company",
  });

  const [projectIdeaField, projectIdeaProps] = useFormField("", {
    placeholder: "describe your project idea",
  });

  const [emailField, emailProps] = useFormField("", {
    placeholder: "your email id",
  });

  const [phoneField, phoneProps] = useFormField("", {
    placeholder: "your phone number",
    type: "number",
  });

  useEffect(() => {
    if (isInquiryFormOpen && typeof isInquiryFormOpen !== "boolean") {
      if (isInquiryFormOpen === "1:1 Consultation")
        setSelectedOptions([FORM_OPTIONS.CONSULTATION]);
      if (isInquiryFormOpen === "360-degree Branding")
        setSelectedOptions([FORM_OPTIONS.BRANDING]);
      if (isInquiryFormOpen === "Product Development")
        setSelectedOptions([FORM_OPTIONS.DEVELOPMENT]);
    }
    if (!isInquiryFormOpen) {
      resetFormFields();
    }
  }, [isInquiryFormOpen]);

  function resetFormFields() {
    [
      nameField,
      designationField,
      companyField,
      emailField,
      projectIdeaField,
      phoneField,
    ].map((i) => {
      i.setValue("");
      i.setError("");
    });
    setSelectedOptions([FORM_OPTIONS.CONSULTATION]);
  }

  function handleOption(opt) {
    const currentSelected = [...selectedOptions];
    const foundAt = currentSelected.findIndex((f) => f === opt);
    if (foundAt !== -1) {
      // REMOVE
      if (currentSelected.length === 1) {
        // Keep Atleast 1 item selected
        return;
      }
      currentSelected.splice(foundAt, 1);
      setSelectedOptions(currentSelected);
    } else {
      //ADD
      currentSelected.push(opt);
      setSelectedOptions(currentSelected);
    }
  }

  async function submitForm() {
    const data = {
      name: nameField.value.trim(),
      company: companyField.value.trim(),
      designation: designationField.value.trim(),
      message: projectIdeaField.value.trim(),
      email: emailField.value.trim(),
      phone: phoneField.value.trim(),
      services: selectedOptions,
    };

    //submit data using useMutation
    await mutateEnquiry.mutateAsync(data);
  }

  function handleValidation() {
    let error = false;
    [nameField, designationField, companyField, phoneField].map((i) => {
      if (!i.value.trim()) {
        error = true;
        i.setError(true);
      }
    });
    if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        emailField.value.trim()
      )
    ) {
      error = true;
      emailField.setError(true);
    }
    if (
      new RegExp(/[\D]+/g).test(phoneField.value) ||
      phoneField.value.length !== 10
    ) {
      error = true;
      phoneField.setError(true);
    }

    if (!error) {
      submitForm();
    }
  }

  return (
    <>
      <CustomDialog
        open={Boolean(isInquiryFormOpen)}
        onClose={closeInquiryForm}
        fullScreen={isMobile}
      >
        <Stack spacing={3} mt={[8, 8, 4]} mb={8} mx={[2, 2, 0]}>
          <Row>
            <Text>My name is</Text>
            <FormInput {...nameProps} />
          </Row>
          <Row>
            <Text>and I&apos;m a</Text>
            <FormInput {...designationProps} />
            <Text>at</Text>
            <FormInput {...companyProps} />
          </Row>
          <Row>
            <Text>I&apos;d like to discuss</Text>
            <Options
              list={[
                FORM_OPTIONS.CONSULTATION,
                FORM_OPTIONS.BRANDING,
                FORM_OPTIONS.DEVELOPMENT,
              ]}
              selectedOptions={selectedOptions}
              onSelect={handleOption}
            />
          </Row>
          <Row>
            <FormInputArea rows={1} maxLength={2000} {...projectIdeaProps} />
          </Row>
          <Row>
            <Text>Contact me back at</Text>
            <FormInput {...emailProps} />
          </Row>
          <Row>
            <Text>My phone number is</Text>
            <FormInput {...phoneProps} />
          </Row>
        </Stack>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          mb={[8, 8, 0]}
        >
          <LoadingWebButton
            isFilled
            onClick={handleValidation}
            isLoading={mutateEnquiry.isLoading}
          >
            SUBMIT
          </LoadingWebButton>
        </Box>
      </CustomDialog>
    </>
  );
}

const Row = ({ children }) => (
  <Stack
    direction={["column", "column", "row"]}
    display="flex"
    width="100%"
    gap={[1, 1, 0]}
  >
    {children}
  </Stack>
);
const Text = ({ children }) => (
  <Typography color="web.white" fontSize="18px">
    {children}
  </Typography>
);
const Options = ({ list, selectedOptions, onSelect }) => (
  <Stack
    direction={["column", "column", "row"]}
    gap={1.6}
    mx={[0, 0, 2]}
    mt={[2, 2, 0]}
  >
    {list.map((lItem) => (
      <Box key={lItem}>
        <Button
          variant="text"
          onClick={() => onSelect(lItem)}
          sx={(theme) => {
            const color = selectedOptions.includes(lItem)
              ? theme.palette.web.white
              : theme.palette.web.transparentWhiteText;
            return {
              cursor: "pointer",
              color: color,
              border: `1.2px solid ${color}`,
              borderRadius: 20,
              fontSize: 12,
              fontWeight: theme.typography.fontWeightRegular,
              "&:hover": {
                backgroundColor: theme.palette.web.transparentWhite,
              },
            };
          }}
        >
          {lItem}
        </Button>
      </Box>
    ))}
  </Stack>
);
