import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  type: PropTypes.oneOf(["logoOnly", "withText"]),
};

Logo.defaultProps = {
  disabledLink: false,
  sx: {},
  type: "logoOnly",
};

export default function Logo({ disabledLink = false, type, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  let logo = (
    <Box
      component="img"
      src="/static/logo.png"
      sx={{ width: 40, height: 40, ...sx }}
    />
  );

  if (type === "withText") {
    logo = (
      <Box
        component="img"
        src="/static/logoWithText.png"
        sx={{ width: 40, height: "auto", ...sx }}
      />
    );
  }

  // const logo = (
  //   <Box sx={{ width: 40, height: 40, ...sx }}>
  //     {type === "longoOnly" && (
  //       <img src="/static/logo.png" style={{ height: "auto", width: "100%" }} />
  //     )}
  //     {type === "withText" && (
  //       <img
  //         src="/static/logoWithText.png"
  //         style={{ height: "auto", width: "100%" }}
  //       />
  //     )}
  //   </Box>
  // );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
