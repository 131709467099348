import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { ButtonCTA } from "../style/styledComponents";

export default function Hero() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fSizes = [42, 44, 44, 60, 80];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      flexDirection="column"
      pt={["17vh", "17vh", "22vh", "22vh", "22vh"]}
      pb={["27vh", "27vh", "22vh", "22vh", "22vh"]}
    >
      <Typography
        textAlign="center"
        color="web.black"
        fontWeight="extraBold"
        fontSize={fSizes.map((x) => (x < 50 ? x * 1.6 : x * 1.85))}
        sx={{
          WebkitTextStroke: "3px transparent",
          lineHeight: 1,
          marginBottom: fSizes.map((x) => x * 1.85 * -0.035),
          background: `linear-gradient(0deg, ${theme.palette.web.black} 20%, rgba(255,255,255,0.5) 200%)`,
          WebkitBackgroundClip: "text",
        }}
      >
        STUDIO
      </Typography>
      <Typography
        component="span"
        textAlign="center"
        color="web.white"
        bgcolor={theme.palette.web.black}
        fontWeight="extraBold"
        fontSize={fSizes}
        lineHeight={1.3}
      >
        <Box
          sx={{
            display: "inline",
            position: "relative",
          }}
        >
          360
          <Box
            component="img"
            height={"25%"}
            src="static/web/degree.png"
            style={{
              position: "absolute",
              top: "20%",
              right: "-18%",
              aspectRatio: "1/1",
            }}
          />
        </Box>
        &nbsp;&nbsp; CREATIVE {isMobile ? <br /> : <></>}
        SERVICES
      </Typography>
      <Typography
        color="web.white"
        fontWeight="light"
        fontSize={[14, 14, 14, 17, 18]}
        textAlign="center"
        px={[3, 3, 5, 5, 5]}
        my={[3]}
        maxWidth={["inherit", "inherit", "inherit", "60vw", "60vw"]}
      >
        We assist founders, stakeholders and teams to turn their ideas into
        reality by providing guidance and support throughout the entire process
        of ideation, design, development to product launch.
        {/* Ignite Your Brand&apos;s Success with Expertise in Branding, Design,
        {isMobile ? <></> : <br />}
        Marketing, Web Development & Getting the Team You Need */}
      </Typography>
      <Box display="flex" justifyContent="center" mb={10} mt={4}>
        <ButtonCTA isFilled />
      </Box>
    </Box>
  );
}
