import Axios from "axios";
import _ from "lodash-es";
import { store } from "../redux/store/store";
import { notiRef } from "../hooks/useNotify";
import actions from "../redux/actions/actions";

export const API_BASE_URL = "https://api.qbit.ventures/v1/";
//Endpoints
export const signIn = "auth/login";

export const enquiry = "enquiry";

class AppsApi {
  axios;
  getApi;
  postApi;
  postFormDataApi;
  patchApi;
  deleteApi;
  baseUrl;
  handleResponse;
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.axios = Axios.create({
      baseURL: API_BASE_URL,
      timeout: 10000,
      validateStatus: (status) => true,
    });

    this.handleResponse = (response, url, data) => {
      const res = response.data;
      if (res.status && res.status === 1) {
        return res;
      }
      if (res.statusCode === 401) {
        store.dispatch(actions.logout());
        notiRef?.current?.toastError(
          res.message || "Oops! You are Unauthorized!"
        );
        return;
      }
      if (res.message && Array.isArray(res.message)) {
        notiRef?.current?.toastError(
          res.message[0] || "Oops! Something went wrong!"
        );
        return;
      }
      if (res.message && typeof res.message === "string") {
        notiRef?.current?.toastError(res.message);
        return;
      }
      throw res;
    };

    this.initSetup();
  }

  initSetup = () => {
    this.getApi = async (url) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      try {
        const res = await this.axios.get(url);
        return this.handleResponse(res, url);
      } catch (e) {
        console.error(e);
        notiRef?.current?.toastError("Something went wrong!");
      }
    };

    this.postApi = (url, data) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      const axiosConfig = {};
      // if (url.includes(sendNotificationWithMedia)) {
      //   axiosConfig.headers = {
      //     "Content-Type": "multipart/form-data",
      //   };
      // }
      return this.axios
        .post(url, data, axiosConfig)
        .then((res) => this.handleResponse(res, url, data))
        .catch((e) => {
          console.error(e);
          notiRef?.current?.toastError("Something went wrong!");
        });
    };

    this.patchApi = (url, data) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      return this.axios
        .patch(url, data)
        .then((res) => this.handleResponse(res, url, data))
        .catch((e) => {
          console.error(e);
          notiRef?.current?.toastError("Something went wrong!");
        });
    };

    this.deleteApi = (url) => {
      const token = store ? store.getState().reducer.authToken : "";
      if (token) {
        this.axios.defaults.headers.Authorization = `Bearer ${token}`;
      }
      return this.axios
        .delete(url)
        .then((res) => this.handleResponse(res, url))
        .catch((e) => console.error(e));
    };
  };

  // All Endpoints
  getEnquiries = async () => {
    try {
      const response = await this.getApi(enquiry);
      if (response) {
        return response;
      }
    } catch (error) {
      console.error("Error fetching data", error);
      throw new Error("Error fetching data");
    }
  };
  // createNotification = (params) =>
  //   this.postApi(sendNotificationWithoutMedia, params);
  // createNotificationWithMedia = (params) =>
  //   this.postApi(sendNotificationWithMedia, params);

  // updateNotification = ({ params, id }) =>
  //   this.patchApi(`${notification}/${id}`, params);
  // deleteNotification = (id) => this.deleteApi(`${notification}/${id}`);
}

const appsApi = new AppsApi(API_BASE_URL);
export default appsApi;
