import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { useSpring, animated } from "@react-spring/web";
const AnimtedBox = animated(Box);

export default function SlideIndicator({ activeSlideIndex }) {
  const [springs, api] = useSpring(() => ({
    from: { left: "0%" },
  }));

  useEffect(() => {
    switch (activeSlideIndex) {
      case 0:
        api.start({
          from: {
            left: "66%",
          },
          to: {
            left: "0%",
          },
        });
        break;
      case 1:
        api.start({
          from: {
            left: "0%",
          },
          to: {
            left: "33%",
          },
        });
        break;
      case 2:
        api.start({
          from: {
            left: "33%",
          },
          to: {
            left: "66%",
          },
        });
        break;
    }
  }, [activeSlideIndex]);

  return (
    <Box width="90px" position="relative">
      <Box
        width="100%"
        height={2}
        bgcolor="web.transparentWhite"
        position="absolute"
        top={0}
      />
      <AnimtedBox
        width="42%"
        height={6}
        bgcolor="web.white"
        position="absolute"
        top={-2}
        borderRadius={10}
        style={{
          ...springs,
        }}
      />
    </Box>
  );
}
