import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Container, Dialog, IconButton, Slide } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import WebLogo from "./WebLogo";
import DrawerMenu from "./DrawerMenu";
import Iconify from "../../components/Iconify";
import { NavbarWrapper } from "../style/styledComponents";

const TransitionUp = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionLeft = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CustomDialog(props) {
  const { open, onClose, fullScreen = false, ...rest } = props;
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={fullScreen ? TransitionLeft : TransitionUp}
      fullScreen={fullScreen}
      {...rest}
      sx={{
        "& .MuiBackdrop-root": {
          background: "rgba(0,0,0,0.85)",
        },
        "& .MuiPaper-root": {
          maxWidth: "700px",
        },
      }}
      PaperProps={{
        sx: {
          background: theme.palette.web.modalBackground,
          ...(fullScreen && {
            backgroundColor: theme.palette.web.modalBackground,
          }),
          ...(!fullScreen && {
            borderRadius: 2,
            px: 1,
            py: 6,
          }),
          boxShadow: `inset 0px 3px 1px -2px ${theme.palette.web.modalBackground},0px 2px 2px 0px ${theme.palette.web.modalBackground},0px 1px 5px 0px ${theme.palette.web.modalBackground}`,
        },
      }}
    >
      <Container maxWidth={false}>
        {fullScreen ? (
          <NavbarWrapper>
            <WebLogo />
            <IconButton onClick={onClose}>
              <Iconify icon="octicon:dash-16" sx={{ color: "white", my: 1 }} />
            </IconButton>
          </NavbarWrapper>
        ) : (
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", right: 5, top: 0 }}
          >
            <Iconify icon="octicon:dash-16" sx={{ color: "white", m: 1.5 }} />
          </IconButton>
        )}
        {props.children}
      </Container>
    </Dialog>
  );
}
