import React, { createContext, useContext } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GlobalAlert from "../components/GlobalAlert";
import { useEffectSkipFirst } from "../hooks/useEffectSkipFirst";
import { notiRef } from "../hooks/useNotify";
import actions from "../redux/actions/actions";
// import { useSocket } from "../hooks/useSocket";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const { authToken, userData } = useSelector((state) => state.reducer);
  const navigate = useNavigate();

  useEffectSkipFirst(() => navigate("/", { replace: true }), [authToken]);
  // useSocket(authToken);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  const value = {
    authToken: authToken,
    userData: userData,
    handleLogout: handleLogout,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthContext.Provider value={value}>
        <GlobalAlert ref={notiRef} />
        {children}
      </AuthContext.Provider>
    </LocalizationProvider>
  );
};
