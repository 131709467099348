import { useTheme } from "@emotion/react";
import {
  Box,
  ButtonBase,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import { WE_ARE_GOOD_AT } from "../constants";
import { useRef, useState } from "react";
import { useInterval } from "../helpers/hooks";
import { useCommon } from "../../utils/CommonContext";
import SlideIndicator from "../components/SlideIndicator";

export default function WeAreGoodAt() {
  const theme = useTheme();
  const { inquiryForm } = useCommon();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [sliderIndex, setSliderIndex] = useState(0);
  const sliderContainer = useRef();
  const cardRef = useRef();

  const intervalCallback = () => {
    if (isMobile) return;
    try {
      if (sliderContainer.current && sliderContainer.current.children) {
        const { offsetWidth, scrollLeft } = sliderContainer.current;
        const cardWidth = cardRef.current.offsetWidth;
        const scrollTo = ((offsetWidth - cardWidth) / 2).toFixed(0);
        if (sliderIndex === 0) {
          setSliderIndex(1);
          sliderContainer.current.scrollLeft = scrollTo;
        }
        if (sliderIndex === 1) {
          setSliderIndex(2);
          sliderContainer.current.scrollLeft = cardWidth * 2;
        }
        if (sliderIndex === 2) {
          setSliderIndex(0);
          sliderContainer.current.scrollLeft = 0;
        }
      }
    } catch (error) {
      console.log("===>  setInterval  error:", error);
    }
  };
  useInterval(intervalCallback, 3000);

  const handleClick = (fromItem) => {
    inquiryForm.openInquiryForm(fromItem);
  };

  const border = `1.6px solid ${theme.palette.web.transparentWhite}`;

  const topbar = (
    <Stack direction="row" justifyContent="space-between">
      <Box display="flex">
        <ArrowDownward
          sx={{
            color: "web.transparentWhiteText",
            fontSize: 20,
            mr: 2,
          }}
        />
        <Typography
          color="web.transparentWhiteText"
          fontWeight="regular"
          fontSize={14.32}
        >
          What we&apos;re good at:
        </Typography>
      </Box>
      {!isMobile && <SlideIndicator activeSlideIndex={sliderIndex} />}
    </Stack>
  );

  const slider = WE_ARE_GOOD_AT.map((item, index) => (
    <Box
      ref={index == 0 ? cardRef : null}
      key={item.title + index}
      width={["100%", "100%", "49%"]}
      border={border}
      borderBottom={
        isMobile ? (index === WE_ARE_GOOD_AT.length - 1 ? border : 0) : border
      }
      borderLeft={isMobile ? 0 : border}
      borderRight={
        isMobile ? 0 : index === WE_ARE_GOOD_AT.length - 1 ? border : 0
      }
      minWidth={["100%", "100%", "49%"]}
      sx={{
        "& .title": {
          borderBottom: "4px solid transparent",
        },
        "&:hover": {
          "& .title": {
            borderBottom: "4px solid white",
          },
          "& .arrowBtn": {
            transform: "scale(1.3)",
          },
        },
        transition: theme.transitions.create(
          ["background-color", "transform"],
          {
            duration: theme.transitions.duration.standard,
          }
        ),
      }}
    >
      <Grid container px={[0, 0, 4]} py={[6, 6, 4]}>
        <Grid item xs={12}>
          <ButtonBase
            onClick={() => handleClick(item.title)}
            color="web.white"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              mb: 6,
            }}
          >
            <Typography
              className="title"
              color="web.white"
              fontWeight="semiBold"
              fontSize={[24, 24, 24, 24, 26]}
            >
              {item.title}
            </Typography>
            <ArrowOutwardIcon
              className="arrowBtn"
              sx={{ color: "web.white", fontSize: [24, 24, 24, 24, 26] }}
            />
          </ButtonBase>
        </Grid>
        {item.points.map((p) => (
          <Grid item xs={6} key={p} pt={1}>
            <Typography
              color="web.white"
              fontWeight="light"
              fontSize={[14, 14, 14, 14, 18]}
            >
              {p}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  ));

  return (
    <Box
      width="100%"
      mb={[15, 15, 25, 25, 35]}
      maxWidth="1500px"
      alignSelf="center"
      mx="auto"
      id="services"
      py={5}
    >
      {topbar}
      <Box
        ref={sliderContainer}
        width="100%"
        my={[1, 1, 4]}
        display="flex"
        flexWrap={isMobile ? "wrap" : "noWrap"}
        overflow={!isMobile ? "hidden" : "auto"}
        sx={{
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {slider}
      </Box>
    </Box>
  );
}
