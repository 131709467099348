/* eslint-disable react-hooks/rules-of-hooks */
import { Navigate, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//Landing Pages
// Pages
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import DashboardApp from "./pages/DashboardApp";
import Landing from "./website/pages/Landing";
import Enquiry from "./pages/Enquiry";

const websiteRoutes = [
  { path: "/", element: <Landing /> },
  { path: "404", element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" /> },
];

const publicRoutes = [
  {
    path: "/",
    children: [...websiteRoutes],
  },
  { path: "admin", element: <Login /> },
];

const protectedRoutes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", element: <DashboardApp /> },
      // { path: "user", element: <User /> },
      { path: "enquiry", element: <Enquiry /> },
    ],
  },
  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "404", element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" /> },
];

export default function Router() {
  const { authToken } = useSelector((state) => state.reducer);
  if (authToken) return useRoutes(protectedRoutes);
  return useRoutes(publicRoutes);
}
