import { useTheme } from "@emotion/react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import FormatQuoteOutlinedIcon from "@mui/icons-material/FormatQuoteOutlined";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { useRef } from "react";
import { MainContainer, SectionTitle } from "../style/styledComponents";

export default function AboutUs() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("xl"));
  const paddingTop = "78px";
  const boxRef = useRef();

  return (
    <>
      <Grid
        container
        ref={boxRef}
        position="relative"
        mb={[15, 15, 25, 25, 35]}
        py={5}
        id="about"
      >
        <MainContainer>
          <Box
            mb={{ xs: 5 }}
            display="flex"
            height="100%"
            pt={paddingTop}
            position={["inherit", "inherit", "absolute"]}
          >
            <SectionTitle lineHeight={1}>ABOUT US</SectionTitle>
          </Box>
        </MainContainer>
        <Grid item md={6} xs={1}></Grid>
        <Grid
          item
          md={6}
          xs={11}
          border={`1px solid ${theme.palette.web.transparentWhite}`}
          borderRadius={5}
          borderRight={0}
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Stack
            flexDirection="column"
            pt={paddingTop}
            pb="48px"
            px={8}
            position="relative"
          >
            {isMd ? (
              <FormatQuoteIcon
                sx={{
                  position: "absolute",
                  color: "rgba(255,255,255,0.5)",
                  top: "25px",
                  left: "60px",
                  height: "40px",
                  width: "40px",
                  transform: "rotate(180deg)",
                }}
              />
            ) : (
              <FormatQuoteOutlinedIcon
                sx={{
                  position: "absolute",
                  color: "web.white",
                  top: "25px",
                  left: "60px",
                  height: "40px",
                  width: "40px",
                  transform: "rotate(180deg)",
                }}
              />
            )}

            <Typography
              color="web.white"
              fontWeight="light"
              fontSize={[15, 15, 18]}
              textAlign="left"
            >
              We drive results. A leading venture studio specializing in
              branding,{isLg ? <br></br> : <>&nbsp;</>}development, and team
              solutions. Let us bring your vision to life.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
