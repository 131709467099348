import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import useViewDisplay from "../../hooks/useViewDisplay";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ProjectBox from "../components/ProjectBox";
import { PROJECTS_INFO } from "../constants";
import { SectionTitle } from "../style/styledComponents";

export default function Projects() {
  const { desktopOnly } = useViewDisplay();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const border = `1px solid ${theme.palette.web.transparentWhite}`;
  const handleViewAll = () => {};

  const showViewAll = false;
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        mb={[15, 15, 25, 25, 35]}
        py={5}
        id="projects"
      >
        <Grid container direction="row" alignItems="flex-end" my={1.5}>
          <Grid item xs={6}>
            <SectionTitle width="50%">PROJECTS</SectionTitle>
          </Grid>
          <Grid item xs={6} pb={1}>
            {isMd ? (
              <Box
                display={showViewAll ? "flex" : "none"}
                justifyContent="flex-end"
              >
                <Button variant="text" onClick={handleViewAll}>
                  <Typography color="web.transparentWhiteText" fontSize={11}>
                    View All
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box
                borderBottom="3px solid white"
                width="100%"
                mb={[2, 2, 3]}
                display="none"
              >
                <Stack direction="column">
                  <Typography
                    mb={1}
                    color="web.white"
                    fontWeight="regular"
                    fontSize={16}
                  >
                    100+ Projects
                  </Typography>
                </Stack>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          direction="row"
          borderTop={border}
          borderBottom={border}
          alignItems="flex-start"
        >
          <Grid
            item
            xs={0}
            md={2}
            p={3}
            sx={{
              display: showViewAll ? desktopOnly : "none",
              height: "100%",
            }}
          >
            <Button onClick={handleViewAll}>
              <ArrowForwardIcon
                sx={{
                  color: "web.transparentWhiteText",
                  fontSize: 20,
                  mr: 2,
                }}
              />
              <Typography
                color="web.transparentWhiteText"
                fontWeight="regular"
                fontSize={14.32}
              >
                View all
              </Typography>
            </Button>
          </Grid>

          <Grid item container xs={12} md={showViewAll ? 10 : 12}>
            {PROJECTS_INFO.map((n) => (
              <ProjectBox
                key={n.projectName}
                projectName={n.projectName}
                projectDescription={n.projectDescription}
                onClick={n.onClick}
                branding={n.branding}
                border={n.border}
                imageFirst={n.imageFirst}
                imgSrc={n.imgSrc}
                isLast={n.isLast}
                showViewAll={showViewAll}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
