import _ from "lodash-es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const getRolesLabel = (role) => {
  const roles = {
    super_admin: "Super Admin",
    admin: "Admin",
    client: "Client",
    partner: "Partner",
  };
  return roles[role];
};
export const getFormattedDate = (date, format = "DD/MM/YYYY hh:mm A") => {
  return dayjs(date).format(format);
};
