import { useEffect, useRef, useState } from "react";

export function useFormField(defaultState = "", props = {}) {
  const [value, setValue] = useState(defaultState);
  const [error, setError] = useState(false);

  function onChange(e) {
    if (props.type && props.type == "number") {
      if (
        e.target.value.length <= 12 &&
        !new RegExp(/[\D]+/g).test(e.target.value)
      ) {
        setValue(e.target.value);
        setError(false);
      }
      return;
    }
    setValue(e.target.value);
    setError(false);
  }

  return [
    {
      value,
      setValue,
      error,
      setError,
    },
    { value: value, onChange: onChange, error: error, ...props },
  ];
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
