import {
  Card,
  Stack,
  Container,
  Typography,
  TableRow,
  TableCell,
  useTheme,
} from "@mui/material";
// components
import Page from "../components/Page";
import CommonTable from "../components/CommonTable";
import { useQuery } from "react-query";
import Label from "../components/Label";
import { sentenceCase } from "change-case";
import appsApi from "../api/api";

import QUERY_KEYS from "../_mock/queryKeys";
import { useState } from "react";
import { useNotify } from "../hooks/useNotify";
import { ENQUIRY_STATUS } from "../constants";
import { getFormattedDate } from "../utils/commons";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "phone", label: "Contact", alignRight: false },
  { id: "company", label: "Company", alignRight: false },
  { id: "designation", label: "Designation", alignRight: false },
  { id: "message", label: "Message", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  // { id: "action", label: "Action", alignRight: true },
];

// ----------------------------------------------------------------------

export default function Enquiry() {
  const {
    isLoading,
    error,
    data = { data: [] },
  } = useQuery(QUERY_KEYS.ENQUIRY_DATA, appsApi.getEnquiries);

  const enquiryList = data?.result?.docs || [];

  const notify = useNotify();

  const [openForm, setOpenForm] = useState(false);

  return (
    <Page title="Inquiry">
      <Container>
        {/* <StatsCardBar /> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Enquiry
          </Typography>

          {/* <Button
            onClick={() => setOpenForm(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Inquiry
          </Button> */}
        </Stack>
        <Card>
          <CommonTable
            loading={isLoading}
            tableTitle="Enquiry"
            tableHead={TABLE_HEAD}
            list={enquiryList || []}
            RowItem={(params) => <RowItem {...params} />}
          />
        </Card>
      </Container>
    </Page>
  );
}

const RowItem = ({ row, selected }) => {
  const { id, name, company, phone, designation, message, status, createdAt } =
    row;
  const theme = useTheme();

  let color = "error";
  if (status == ENQUIRY_STATUS.INPROCESS) color = "warning";
  if (status == ENQUIRY_STATUS.OPEN) color = "default";
  if (status == ENQUIRY_STATUS.DONE) color = "success";

  return (
    <TableRow hover key={id}>
      <TableCell component="th" scope="row">
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>
      <TableCell align="left">{phone}</TableCell>
      <TableCell align="left">{company}</TableCell>
      <TableCell align="left">{designation}</TableCell>
      <TableCell align="left">{sentenceCase(message)}</TableCell>
      <TableCell align="left">
        <Label variant="ghost" color={color}>
          {sentenceCase(status)}
        </Label>
      </TableCell>
      <TableCell align="left">{getFormattedDate(createdAt)}</TableCell>
      {/* <TableCell align="right">
        <MoreMenu onDelete={() => {}} onEdit={() => {}} />
      </TableCell> */}
    </TableRow>
  );
};
