// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "enquiry",
    path: "/enquiry",
    icon: getIcon("gridicons:multiple-users"),
  },
  // {
  //   title: "appointment",
  //   path: "/appointment",
  //   icon: getIcon("akar-icons:schedule"),
  // },
  // {
  //   title: "program",
  //   path: "/program",
  //   icon: getIcon("academicons:ideas-repec"),
  // },
  // {
  //   title: "Products Portfolio",
  //   path: "/products-portfolio",
  //   icon: getIcon("bytesize:portfolio"),
  // },
  // {
  //   title: "insights",
  //   path: "/insights",
  //   icon: getIcon("carbon:blog"),
  // },
  // {
  //   title: "Case Study",
  //   path: "/case-study",
  //   icon: getIcon("bxs:report"),
  // },
  // {
  //   title: "Services",
  //   path: "/service",
  //   icon: getIcon("carbon:cloud-service-management"),
  // },
  // {
  //   title: "Testimonials",
  //   path: "/testimonial",
  //   icon: getIcon("ic:baseline-feedback"),
  // },
  // {
  //   title: "Clients",
  //   path: "/Clients",
  //   icon: getIcon("bxs:user-check"),
  // },
  // {
  //   title: "FAQ's",
  //   path: "/faqs",
  //   icon: getIcon("wpf:faq"),
  // },
  // {
  //   title: "Carrier",
  //   path: "/carrier",
  //   icon: getIcon("ic:round-work-outline"),
  // },
  // {
  //   title: "Users & Roles",
  //   path: "/user",
  //   icon: getIcon("eva:people-fill"),
  // },
];

export default navConfig;
