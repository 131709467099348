import { useTheme } from "@emotion/react";
import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { SectionTitle } from "../style/styledComponents";

const OurProcessBox = (props) => {
  const { border, processTitle, processDescription, moreSpace } = props;
  return (
    <>
      {moreSpace ? <Box my={[0, 0, 10]} /> : <></>}
      <Box mt={2} mb={4} py={[3, 3, 6]}>
        <ButtonBase
          disableRipple
          onClick={() => {}}
          color="web.white"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            mb: 1,
            px: [0, 0, 5],
            py: 2,
            borderBottom: border,
          }}
        >
          <Typography
            color="web.white"
            fontWeight="semiBold"
            fontSize={[24, 24, 26]}
            textTransform="uppercase"
          >
            {processTitle}
          </Typography>
          {/* <ArrowOutwardIcon
            sx={{ color: "web.white", fontSize: [20, 22, 22] }}
          /> */}
        </ButtonBase>
        <Typography
          color="web.white"
          fontWeight="light"
          fontSize={[12, 14, 16]}
          textAlign="left"
          display="flex"
          px={[0, 0, 5]}
          pt={2}
        >
          {processDescription}
        </Typography>
      </Box>
    </>
  );
};
export default function OurProcess() {
  const theme = useTheme();
  const border = `1px solid ${theme.palette.web.transparentWhite}`;

  const topbar = (
    <>
      <SectionTitle>OUR PROCESS</SectionTitle>
      <Stack direction="row" justifyContent="space-between" mt={[3, 0, 0]}>
        <Box display="flex">
          <ArrowDownwardIcon
            sx={{
              color: "web.transparentWhiteText",
              fontSize: 20,
              mr: 2,
            }}
          />
          <Typography
            color="web.transparentWhiteText"
            fontWeight="regular"
            fontSize={14.32}
          >
            What to Expect:
          </Typography>
        </Box>
      </Stack>
    </>
  );
  const body = (
    <>
      <Grid
        container
        borderTop={[0, 0, border]}
        borderBottom={[0, 0, border]}
        mt={2}
        py={[0, 0, 6]}
      >
        <Grid item md={6} xs={12} borderRight={[0, 0, border]}>
          <Stack height="100%" spacing={[0, 0, 20]} display="flex">
            <OurProcessBox
              border={border}
              processDescription="A comprehensive SWOT analysis, identifying the client's strengths, weaknesses, opportunities, and threats."
              processTitle="Strategy"
            />
            <OurProcessBox
              border={border}
              processDescription="A user-centric design approach, ensuring that the solution meets the needs and expectations of the target audience."
              processTitle="Solution"
            />
          </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
          <Stack display="flex">
            <OurProcessBox
              moreSpace={true}
              border={border}
              processDescription="A seamless integration of the solution into the existing systems, without disrupting any existing workflows or processes."
              processTitle="Implementation"
            />
            {/* <OurProcessBox
              moreSpace={true}
              border={border}
              processDescription="Lorek laskjdmL askdllaksdna als dnalsdnals
        laknsd akjnsd kajdu akdjnas, nkasjnd asda"
              processTitle="BRAND DEVELOPMENT"
            /> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
  return (
    <Box mb={[15, 15, 25, 25, 35]} py={5}>
      {topbar}
      {body}
    </Box>
  );
}
