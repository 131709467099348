import React, { createContext, useContext, useState } from "react";

const CommonContext = createContext();

export const useCommon = () => useContext(CommonContext);

export const CommonProvider = ({ children }) => {
  // DRAWER MENU Webiste ONLY
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  function closeDrawerMenu(event, reason) {
    setIsDrawerOpen(false);
  }
  function openDrawerMenu() {
    setIsDrawerOpen(true);
  }

  //Inquiry Form Website Only
  const [isInquiryFormOpen, setIsInquiryFormOpen] = useState(false);
  function closeInquiryForm(event, reason) {
    setIsInquiryFormOpen(false);
  }
  function openInquiryForm(item) {
    setIsInquiryFormOpen(item || true);
  }

  const value = {
    drawerMenu: {
      isDrawerOpen: isDrawerOpen,
      openDrawerMenu: openDrawerMenu,
      closeDrawerMenu: closeDrawerMenu,
    },
    inquiryForm: {
      isInquiryFormOpen: isInquiryFormOpen,
      openInquiryForm: openInquiryForm,
      closeInquiryForm: closeInquiryForm,
    },
  };

  return (
    <CommonContext.Provider value={value}>{children}</CommonContext.Provider>
  );
};
