import React, { forwardRef, useImperativeHandle, useState } from "react";
import Box from "@mui/material/Box";
import _ from "lodash-es";
import { Alert, Paper, Slide, Snackbar, Typography } from "@mui/material";
import { useTheme } from "@mui/system";

const GlobalAlert = forwardRef((props, ref) => {
  //For Toast View
  const [toast, setToast] = useState({});
  const [toastWeb, setToastWeb] = useState({});
  const theme = useTheme();

  useImperativeHandle(ref, () => ({
    //Snackbar Types
    toastSuccess: (msg) => handleToast("success", msg),
    toastError: (msg) => handleToast("error", msg),
    toastInfo: (msg) => handleToast("info", msg),
    toastWarning: (msg) => handleToast("warning", msg),
    toastForWeb: (msg) => handleToastWeb("info", msg),
  }));

  const handleToastWeb = (type = "success", message = "Hello!") => {
    setToastWeb({
      severity: type,
      message: message,
    });
  };

  const handleToast = (type = "success", message = "Hello!") => {
    setToast({
      severity: type,
      message: message,
    });
  };

  return (
    <>
      {/* To Display Custom Toast Messages Only */}
      {!_.isEmpty(toast) && !_.isEmpty(toast.message) && (
        <Snackbar
          open={Boolean(toast.message)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3500}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
          onClose={() => setToast({})}
        >
          <Box boxShadow={3} borderRadius={1} overflow="hidden">
            <Alert
              variant="filled"
              onClose={() => setToast({})}
              severity={toast.severity}
            >
              {toast.message}
            </Alert>
          </Box>
        </Snackbar>
      )}
      {!_.isEmpty(toastWeb) && !_.isEmpty(toastWeb.message) && (
        <Snackbar
          open={Boolean(toastWeb.message)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3500}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
          onClose={() => setToastWeb({})}
        >
          <Paper
            sx={{
              background: theme.palette.web.white,
              borderRadius: 2,
              px: 6,
              py: 6,
              boxShadow: `inset 0px 3px 1px -2px ${theme.palette.web.modalBackground},0px 2px 2px 0px ${theme.palette.web.modalBackground},0px 1px 5px 0px ${theme.palette.web.modalBackground}`,
            }}
          >
            <Typography
              textAlign="center"
              fontSize={20}
              color={theme.palette.web.black}
            >
              Thanks! We&apos;ll get back to you soon
            </Typography>
          </Paper>
        </Snackbar>
      )}
    </>
  );
});

GlobalAlert.displayName = "GlobalAlert";

export default GlobalAlert;
