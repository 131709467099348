import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Telegram from "@mui/icons-material/Telegram";

export const NAV_LINKS = [
  {
    title: "SERVICES",
    link: "services",
  },
  {
    title: "PROJECTS",
    link: "projects",
  },
  {
    title: "ABOUT",
    link: "about",
  },
  {
    title: "CONTACT",
    link: "contact",
  },
];

export const PROJECTS_INFO = [
  {
    projectName: "IGNITE",
    projectDescription:
      "This product provides training to school students for their competitive exams.  (NDA, KVPY, Olympiad etc.,)",
    onClick: () => {},
    branding: "PRODUCT DESIGN",
    border: `1px solid rgba(255,255,255,0.15)`,
    imageFirst: false,
    imgSrc: "/static/web/ignite.png",
    isLast: false,
  },
  {
    projectName: "PROPHEY",
    projectDescription:
      "It's opinion sharing is a unique platform for young people in India who want to express their opinions and earn money through their knowledge.",
    onClick: () => {},
    branding: "BRANDING",
    border: `1px solid rgba(255,255,255,0.15)`,
    imageFirst: true,
    imgSrc: "/static/web/prophey.png",
    isLast: false,
  },
  {
    projectName: "VARIABLE",
    projectDescription:
      "In real time in categories like Movies, Web series, Competitive exams & many more.",
    onClick: () => {},
    branding: "PRODUCT DESIGN, DEVELOPMENT",
    border: `1px solid rgba(255,255,255,0.15)`,
    imageFirst: false,
    imgSrc: "/static/web/variable.png",
    isLast: true,
  },
];
const commonProps = [
  {
    color: "web.white",
    height: ["28px", "28px", "26px"],
    width: "100%",
  },
];
// const handleDiscord = () => {
//   window.open("https://discord.gg/dr4jXgkuDD");
// };
export const SOCIAL_LINKS = [
  {
    title: "Instagram",
    onClick: () => window.open("https://www.instagram.com/blockwhalelabs/"),
    component: <InstagramIcon sx={commonProps} />,
  },
  {
    title: "Twitter",
    onClick: () => window.open("https://twitter.com/blockwhalelabs"),
    component: <TwitterIcon sx={commonProps} />,
  },
  {
    title: "LinkedIn",
    onClick: () =>
      window.open("https://www.linkedin.com/company/blockwhalelabsindia"),
    component: <LinkedInIcon sx={commonProps} />,
  },
  // {
  //   title: "Whatsapp",
  //   onClick: () => window.open("https://wa.me/message/BYQEBSIR4XMGB1"),
  //   component: <WhatsApp sx={commonProps} />,
  // },
  // {
  //   title: "Telegram",
  //   onClick: () => window.open("https://t.me/+nm36x6Dm97M3OTk1"),
  //   component: <Telegram sx={commonProps} />,
  // },
];

export const WE_ARE_GOOD_AT = [
  {
    title: "1:1 CONSULTATION",
    points: [
      "Market Research",
      "User Research",
      "Product Strategy",
      "Technical Consulting",
      "Analytics and Metrics",
      "Localization and globalization",
    ],
  },
  {
    title: "360-DEGREE BRANDING",
    points: [
      "Brand Audit",
      "Branding",
      "Marketing Strategy",
      "Content Strategy",
      "Measure and Optimize",
      "Production",
    ],
  },
  {
    title: "PRODUCT DEVELOPMENT",
    points: [
      "Product Audit",
      "Product Design",
      "Product Management",
      "GTM",
      "Monetization Strategy",
      "Product Scalability",
    ],
  },
];

export const FORM_OPTIONS = {
  CONSULTATION: "1:1 Consultation",
  BRANDING: "360-degree Branding",
  DEVELOPMENT: "Product Development",
};
