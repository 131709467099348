import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { linkHoverSx } from "../style/styledComponents";

export default function ProjectBox(props) {
  const {
    border,
    imageFirst,
    imgSrc,
    onClick,
    branding,
    projectName,
    projectDescription,
    isLast,
    showViewAll,
  } = props;

  const commonProps = {
    item: true,
    xs: 12,
    sm: 12,
    md: 6,
    borderLeft: ["", "", border],
    py: [1, 1, 3],
    px: [0, 0, 4],
    display: "flex",
  };

  return (
    <>
      <Grid container item borderBottom={!isLast && border}>
        <Grid
          {...commonProps}
          {...(!showViewAll && { borderLeft: imageFirst && ["", "", border] })} // TODO: Remove if !showViewAll
          justifyContent="flex-start"
          alignItems="space-between"
          order={imageFirst ? { xs: 2, md: 2 } : { xs: 2, md: 1 }}
        >
          <Box width="100%" flexDirection="column" display="flex">
            <ButtonBase
              onClick={onClick}
              color="web.white"
              sx={(theme) => ({
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
                "& .title": {
                  borderBottom: "4px solid transparent",
                },
                "&:hover": {
                  "& .title": {
                    borderBottom: "4px solid white",
                  },
                  "& .arrowBtn": {
                    transform: "scale(1.3)",
                  },
                },
                transition: theme.transitions.create(
                  ["background-color", "transform"],
                  {
                    duration: theme.transitions.duration.standard,
                  },
                ),
              })}
            >
              <Typography
                className="title"
                color="web.white"
                fontWeight="semiBold"
                fontSize={[24, 24, 24, 24, 26]}
              >
                {projectName}
              </Typography>
              <ArrowOutwardIcon
                className="arrowBtn"
                sx={{ color: "web.white", fontSize: [24, 24, 24, 24, 26] }}
              />
            </ButtonBase>
            <Typography
              flex={1}
              my={2}
              p="5px"
              fontSize={[14, 14, 14, 14, 18]}
              fontWeight="light"
              color="web.white"
            >
              {projectDescription}
            </Typography>
            <Typography
              color="web.white"
              p="5px"
              fontSize={[15, 12, 12]}
              py={[5, 5, 0]}
            >
              {branding}
            </Typography>
          </Box>
        </Grid>
        <Grid
          {...commonProps}
          {...(!showViewAll && { borderLeft: !imageFirst && ["", "", border] })} // TODO: Remove if !showViewAll
          order={imageFirst ? { xs: 1, md: 1 } : { xs: 1, md: 2 }}
        >
          <Box
            component="img"
            src={imgSrc}
            width="100%"
            bgcolor="grey"
            mt={[4, 5, 0]}
            style={{ aspectRatio: 1.6 }}
          ></Box>
        </Grid>
      </Grid>
    </>
  );
}
