import { IconButton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import Iconify from "../../components/Iconify";
import { useCommon } from "../../utils/CommonContext";
import { NAV_LINKS } from "../constants";
import { ButtonCTA, LinkText } from "../style/styledComponents";
import CustomDialog from "./CustomDialog";

export default function DrawerMenu({ onNavigate }) {
  const { drawerMenu } = useCommon();
  const { isDrawerOpen, openDrawerMenu, closeDrawerMenu } = drawerMenu;

  const handleClick = (link) => {
    closeDrawerMenu();
    setTimeout(() => {
      onNavigate(link);
    }, 50);
  };

  return (
    <>
      <IconButton onClick={openDrawerMenu}>
        <Iconify
          icon="heroicons-solid:menu-alt-1"
          sx={{ color: "white", my: 1 }}
        />
      </IconButton>
      <CustomDialog
        open={isDrawerOpen}
        onClose={closeDrawerMenu}
        fullScreen={true}
      >
        <Stack spacing={3} mt={6} mb={16}>
          {NAV_LINKS.map((n) => (
            <LinkText key={n.title} onClick={() => handleClick(n.link)}>
              {n.title}
            </LinkText>
          ))}
        </Stack>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <ButtonCTA />
        </Box>
      </CustomDialog>
    </>
  );
}
