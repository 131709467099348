export const ROLES = {
  SUPER_ADMIN: "super admin",
  ADMIN: "admin",
  CLIENT: "client",
  PARTNER: "partner",
};

export const PERMISSIONS = {
  READ: "read",
  WRITE: "write",
  DELETE: "delete",
};

export const ENQUIRY_STATUS = {
  OPEN: "open",
  INPROCESS: "in process",
  DONE: "done",
  CANCELLED: "cancelled",
};

export const APPOINTMENT_STATUS = {
  SCHEDULED: "scheduled",
  RESCHEDULED: "rescheduled",
  CLOSED: "closed",
};

export const MODULES = {
  INQUIRY: "inquiry",
  APPOINTMENT: "appointment",
  PROGRAM: "program",
  PORTFOLIO: "portfolio",
  INSIGHTS: "insights",
  CASE_STUDY: "case study",
  SERVICES: "services",
  TESTIMONIALS: "testimonials",
  CLIENTS: "clients",
  FAQ: "FAQ",
  CARRIER: "Carrier",
  USERS_ROLES: "Users & Roles",
};

export const APPS = {
  MESSANGER: "messenger",
  MEET: "meet",
};
