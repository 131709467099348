import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Badge,
} from "@mui/material";
// components
import Iconify from "../../components/Iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import LanguagePopover from "./LanguagePopover";
import CalendarPopover from "./CalendarPopover";
import MessagePopover from "./MessagesPopover";
import NotificationSidebar from "./NotificationSidebar";
import { useState } from "react";
// import LanguagePopover from "./LanguagePopover";
// import NotificationsPopover from "./NotificationsPopover";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [isOpenNotification, setIsOpenNotification] = useState(false);

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover /> */}
          {/* <IconButton
            // color={true ? "primary" : "default"}
            color={"default"}
            // onClick={handleOpen}
            sx={{ width: 40, height: 40 }}
          >
            <Iconify icon="akar-icons:airplay-audio" width={20} height={20} />
          </IconButton> */}
          {/* <IconButton
            // color={true ? "primary" : "default"}
            color={"default"}
            // onClick={handleOpen}
            sx={{ width: 40, height: 40 }}
          >
            <Iconify icon="wpf:video-call" width={20} height={20} />
          </IconButton> */}
          {/* <MessagePopover />
          <CalendarPopover />
          <Divider orientation="vertical" flexItem />
          <NotificationsPopover /> */}
          <AccountPopover />
          {/* <IconButton
            // color={true ? "primary" : "default"}
            color={"primary"}
            // onClick={handleOpen}
            sx={{ width: 40, height: 40 }}
          >
            <Iconify
              icon="fa6-solid:table-cells-large"
              width={20}
              height={20}
            />
          </IconButton> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
