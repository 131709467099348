import Page from "../../components/Page";
import InquiryForm from "../components/InquiryForm";
// import InquiryForm from "../components/InquiryForm";
import AboutUs from "../sections/AboutUs";
import Footer from "../sections/Footer";
import HaveProject from "../sections/HaveProject";
import Hero from "../sections/Hero";
import Navbar from "../sections/Navbar";
import OurProcess from "../sections/OurProcess";
import Projects from "../sections/Projects";
import WeAreGoodAt from "../sections/WeAreGoodAt";
import { MainContainer } from "../style/styledComponents";

export default function Landing() {
  return (
    <Page title="Qbit | 360° Creative Services" sx={{ bgcolor: "web.black" }}>
      <Navbar />
      <MainContainer>
        <Hero />
        <WeAreGoodAt />
        <Projects />
        <OurProcess />
      </MainContainer>
      <AboutUs />
      <MainContainer>
        <HaveProject />
        <Footer />
      </MainContainer>
      <InquiryForm />
    </Page>
  );
}
