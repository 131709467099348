import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function WebLogo() {
  return (
    <Box
      p={0}
      m={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ userSelect: "none", cursor: "pointer" }}
    >
      <Box
        component="img"
        src="/static/web/new_logo.png"
        height={[32, 32, 32, 32, 36]}
        width={[32, 32, 32, 32, 36]}
        mr={1.5}
      />
      <Typography
        color="web.white"
        fontWeight="extraBold"
        sx={{ userSelect: "none", cursor: "pointer" }}
        fontSize={[22, 22, 22, 22, 24]}
      >
        QBIT.
      </Typography>
    </Box>
  );
}
