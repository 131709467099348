import { useTheme } from "@emotion/react";
import {
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { SOCIAL_LINKS } from "../constants";

export default function Footer() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  //  const handleEnquiry = () => {
  //   window.open("https://wa.me/message/BYQEBSIR4XMGB1");
  // };
  // const handleTelegram = () => {
  //   window.open("https://t.me/+nm36x6Dm97M3OTk1");
  // };
  // const handleLinkedIn = () => {
  //   window.open("https://www.linkedin.com/company/blockwhalelabsindia");
  // };
  // const handleDiscord = () => {
  //   window.open("https://discord.gg/dr4jXgkuDD");
  // };

  return (
    <>
      <Grid
        container
        borderTop={!isMd && `1px solid ${theme.palette.web.transparentWhite}`}
        pb={[5, 5, 0]}
      >
        <Grid item md={6} xs={12} py={[0, 0, 3]} order={{ xs: 2, md: 1 }}>
          <Typography
            textAlign={["center", "center", "left"]}
            color="web.white"
            fontWeight="bold"
            fontSize={[11, 11, 12]}
          >
            <span style={{ fontSize: "larger", fontWeight: "400" }}>
              &#169;
            </span>{" "}
            Qbit Infotech Private Limited {isMd ? <></> : <br></br>}
            2024 | All rights reserved.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Stack
            direction="row"
            justifyContent={["center", "center", "flex-end"]}
            py={3}
          >
            {SOCIAL_LINKS.map((v) => {
              return (
                <IconButton key={v.title} onClick={v.onClick}>
                  {v.component}
                </IconButton>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
