import { Box, Divider, IconButton, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
// import { useState } from "react";
// import useResponsive from "../hooks/useResponsive";
import useViewDisplay from "../../hooks/useViewDisplay";
import DrawerMenu from "../components/DrawerMenu";
import WebLogo from "../components/WebLogo";
import { NAV_LINKS } from "../constants";
import { ButtonCTA, LinkText, NavbarWrapper } from "../style/styledComponents";

export default function Navbar() {
  const { mobileOnly, desktopOnly } = useViewDisplay();
  const onNavigate = (id) => {
    try {
      document.getElementById(id).scrollIntoView();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <NavbarWrapper>
      <WebLogo />
      <Stack direction="row" alignItems="center" sx={{ display: desktopOnly }}>
        {NAV_LINKS.map((n) => (
          <LinkText key={n.title} onClick={() => onNavigate(n.link)}>
            {n.title}
          </LinkText>
        ))}
      </Stack>
      <ButtonCTA sx={{ display: desktopOnly }} />
      <Box sx={{ display: mobileOnly }}>
        <DrawerMenu onNavigate={onNavigate} />
      </Box>
    </NavbarWrapper>
  );
}
