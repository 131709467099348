/* eslint-disable react/display-name */
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>
        {title ? `${title} | Qbit` : "Qbit | 360° Creative Services"}
      </title>
      {/* rest of the code */}
      <title>{"Qbit | 360° Creative Services"}</title>
      <meta property="og:title" content="Qbit | 360° Creative Services" />
      <meta property="og:image" content={`/logo.jpeg`} />
      <meta
        property="og:description"
        content={
          "Qbit Ventures is a venture studio that specializes in building products and services in the market. The company offers a wide range of services including Consulting, Product Development and Branding to help stakeholders, founders and teams."
        }
      />
      <meta
        property="description"
        content={
          "Qbit Ventures is a venture studio that specializes in building products and services in the market. The company offers a wide range of services including Consulting, Product Development and Branding to help stakeholders, founders and teams."
        }
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
