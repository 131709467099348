import { Box, Typography } from "@mui/material";
import { ButtonCTA } from "../style/styledComponents";

export default function HaveProject() {
  return (
    <Box py={5} id="contact">
      <Typography
        textAlign="left"
        color="web.white"
        fontWeight="black"
        fontSize={[24, 24, 42]}
      >
        HAVE A PROJECT IN MIND?
      </Typography>
      <Typography color="web.white" fontWeight="light" fontSize={[22, 22, 40]}>
        BRING IT ON!
      </Typography>
      <Box display="flex" justifyContent="center" mt={8} mb={[10, 10, 20]}>
        <ButtonCTA isFilled />
      </Box>
    </Box>
  );
}
